<template>
  <div class="flex flex-col w-full min-h-screen pb-24 backgroud-img">
    <template v-if="Object.keys(optionals).length > 0">
      <div class="z-10 flex flex-col items-center bg-white shadow-lg md:top-0">
        <stepper :current-step="2" />
      </div>
      <step-progress-bar :step="2"/>
      <div class="full-section">
        <div class="flex flex-wrap">
          <div class="section-additionals w-full lg:pr-12 lg:pl-12 lg:w-1/2 bg-jooycar-gray-translucent">
            <div class="w-full pb-5 pt-20">
              <price-summary
                :plan="selectedPlan"
                :distance-factor="distanceFactor"
                :optionals="selectedRelatedOptionals"
                :fixed-price-optionals="fixedPriceOptionals"
                :kilometers="cleanKilometers(kilometers)"
                @total-price-change="(price) => totalPrice = price"
                @calculated-price-change="(values) => calculatedValues = values"
              />
            </div>
            <div class="w-full mb-10">
              <div class="text-jooycar-blue-dark text-sm font-semibold pt-5">
                Simula cuanto gastarías
              </div>
              <div class="text-jooycar-blue-darkest font-semibold text-xs">
                Mueve el vehículo para calcular tu precio segun tu selección de coberturas
              </div>
              <div>
                <div
                  class="
                    mt-2
                    text-jooycar-blue-sky
                    px-2
                  "
                >
                  <div
                    class="
                      text-renta-aquagreen
                      font-bold
                      pb-5
                    "
                  >
                    {{ kmsTraveled | formatNumber }} KM = {{ kmPrice() | formatCurrency }}
                  </div>
                  <div class="mt-5">
                    <input
                      class="w-full"
                      type="range"
                      min="0"
                      :max="selectedPlan.maxDistance + 100"
                      v-model="kmsTraveled"
                      step="1"
                    >
                  </div>
                </div>
              </div>
              <div
                class="self-center text-xs text-jooycar-blue-sky mt-8"
              >
                El tope mensual es de 1500kms. ¡Maneja tranquilo sobre este tope! no te cobraremos de más.
              </div>
            </div>
            <div class="text-jooycar-blue-dark text-sm mb-5 font-semibold">Opciones que modifican el precio fijo de tu seguro:</div>
            <div class="flex justify-between mb-5 assistance">
              <div class="flex">
                <label
                  class="mr-2 text-sm font-semibold text-jooycar-blue-darkest self-end"
                >
                  Asistencia
                </label>
                <div class="h-5 self-end">
                  <info-tooltip>
                    Servicios adicionales que están incluidos en tu póliza. Los más importantes son los de grúa de
                    rescate, mecánica ligera (carga de batería, cambio de neumáticos, cerrajería, fallas eléctricas, etc.)
                    Puedes ver más detalles estas y otras asistencias
                    <button
                      class="font-bold underline"
                      v-close-popover
                      @click="showAssistancesModal"
                    >
                      ver detalle aquí
                    </button>
                  </info-tooltip>
                </div>
              </div>
              <div class="flex">
                <div
                  class="text-xs font-bold cursor-pointer self-end"
                  :class="optionals['Asistencia'].value == optionals['Asistencia'].alternatives[0] ?
                  ('text-jooycar-blue-dark') : ('text-jooycar-gray-medium')"
                  @click="() => optionals['Asistencia'].value = optionals['Asistencia'].alternatives[0]"
                >
                  {{optionals['Asistencia'].alternatives[0]}}
                </div>
                <toggle-button
                  @change="ToggleButtonAssistance"
                  :value="optionals['Asistencia'].value == optionals['Asistencia'].alternatives[1]"
                  :sync="true"
                  color="#4066B3"
                  :height="32"
                  :width="52"
                  class="mx-2"
                />
                <div
                  class="text-xs font-bold cursor-pointer self-end"
                  :class="optionals['Asistencia'].value == optionals['Asistencia'].alternatives[1] ?
                  ('text-jooycar-blue-dark') : ('text-jooycar-gray-medium')"
                  @click="() => optionals['Asistencia'].value = optionals['Asistencia'].alternatives[1]"
                >
                  {{optionals['Asistencia'].alternatives[1]}}
                </div>
              </div>
            </div>
            <div class="flex justify-between mb-5 flex-row responsive-replace">
              <div class="flex">
                <label
                  class="mr-2 text-sm font-semibold text-jooycar-blue-darkest self-end"
                >
                  Vehículo de Reemplazo
                </label>
                <div class="h-5 self-end">
                  <info-tooltip
                    :text="tooltipTexts.carReplacement"
                  />
                </div>
              </div>
              <div
                class="
                  flex
                  flex-row
                  text-renta-blue-dark
                  responsive-replace-button-box
                ">
                <button
                  v-for="(carReplacementItem, index) in optionals['Vehículo de Reemplazo'].alternatives"
                  :key="index"
                  class="
                    font-bold
                    text-sm
                    uppercase
                    text-center
                    sm:w-24
                    sm:mt-0
                    mt-2
                    w-full
                    h-10
                    rounded
                  "
                  :class="{
                    'mr-2': index < optionals['Vehículo de Reemplazo'].alternatives.length - 1,
                    'bg-jooycar-gray-deductibleBtn text-jooycar-blue-darkest border-jooycar-blue-sky border': carReplacementItem !== optionals['Vehículo de Reemplazo'].value,
                    'bg-jooycar-blue-dark text-white border-jooycar-blue-dark border-2': carReplacementItem === optionals['Vehículo de Reemplazo'].value,
                  }"
                  @click="optionals['Vehículo de Reemplazo'].value = carReplacementItem"
                >
                  {{carReplacementItem.split('Días')[0]}}
                </button>
              </div>
            </div>
            <slider-selector
              v-if="false"
              class="mb-12"
              title="Aspiración de Agua"
              name="water-vacuum"
              :options="optionals['Aspiración de Agua'].alternatives"
              v-model="optionals['Aspiración de Agua'].value"
              :tooltip-text="tooltipTexts.waterVaccuum"
            />
            <div class="flex justify-between mb-24 theft">
              <div class="flex">
                <label
                  class="mr-2 text-sm font-semibold text-jooycar-blue-darkest self-end"
                >
                  Robo Contenido
                </label>
                <div class="h-5 self-end">
                  <info-tooltip
                    :text="tooltipTexts.theft"
                  />
                </div>
              </div>
              <div class="flex">
                <div
                  class="text-xs font-bold cursor-pointer self-end"
                  :class="optionals['Robo Contenido'].value == optionals['Robo Contenido'].alternatives[0] ?
                  ('text-jooycar-blue-dark') : ('text-jooycar-gray-medium')"
                  @click="() => optionals['Robo Contenido'].value = optionals['Robo Contenido'].alternatives[0]"
                >
                  {{optionals['Robo Contenido'].alternatives[0]}}
                </div>
                <toggle-button
                  @change="ToggleButtonTheft"
                  :value="optionals['Robo Contenido'].value == optionals['Robo Contenido'].alternatives[1]"
                  :sync="true"
                  color="#4066B3"
                  :height="32"
                  :width="52"
                  class="mx-2"
                />
                <div
                  class="text-xs font-bold cursor-pointer self-end"
                  :class="optionals['Robo Contenido'].value == optionals['Robo Contenido'].alternatives[1] ?
                  ('text-jooycar-blue-dark') : ('text-jooycar-gray-medium')"
                  @click="() => optionals['Robo Contenido'].value = optionals['Robo Contenido'].alternatives[1]"
                >
                  {{optionals['Robo Contenido'].alternatives[1]}}
                </div>
              </div>
            </div>
            <div class="flex flex-col-reverse items-center mb-8 md:flex-row md:justify-center next-button-box">
              <button
                v-if="true"
                @click="previousStep"
                class="block w-64 px-4 py-3 mt-2 font-semibold text-center text-white md:mt-auto md:mr-1 bg-jooycar-gray-dark"
              >
                VOLVER
              </button>
              <button
                id="submitBtn"
                @click="nextStep"
                class="block w-64 px-4 py-3 mt-auto font-semibold text-center text-white md:ml-1 bg-jooycar-blue hover:bg-jooycar-blue-dark"
              >
                QUIERO CONTRATAR
              </button>
            </div>
          </div>
          <div class="section-additionals w-full lg:pr-12 lg:pl-12 lg:w-1/2 bg-white no-bg-white">
            <div class="text-jooycar-blue-dark text-sm mb-5 font-semibold pt-20 variable-title">
              Las siguientes opciones modifican el precio variable de tu seguro:
            </div>
            <div>
              <label
                  class="mr-2 text-sm font-semibold text-jooycar-blue-darkest self-center"
              >
                Deducible
              </label>
              <info-tooltip
                :text="tooltipTexts.deductible"
              />
              <div
                class="
                  flex
                  sm:flex-row
                  flex-col
                  text-renta-blue-dark
                  pb-5
                ">
                <button
                  v-for="(plan, index) in planOptions"
                  :key="index"
                  class="
                    font-bold
                    text-sm
                    text-center
                    sm:w-24
                    mt-3
                    w-full
                    h-10
                    rounded
                  "
                  :class="{
                    'mr-2': index < planOptions.length - 1,
                    'bg-jooycar-gray-deductibleBtn text-jooycar-blue-darkest border-jooycar-blue-sky border': plan.value !== deductible,
                    'bg-jooycar-blue-dark text-white border-jooycar-blue-dark border-2': plan.value === deductible,
                  }"
                  @click="deductible = plan.value"
                >
                  {{ `${plan.value.split('UF')[0]} UF` }}
                </button>
              </div>
            </div>
            <div>
              <label
                  class="mr-2 text-sm font-semibold text-jooycar-blue-darkest self-center"
              >
                Taller de reparación
              </label>
              <info-tooltip
                :text="tooltipTexts.repairShop"
              />
              <div
                class="
                  flex
                  sm:flex-row
                  flex-col
                  text-renta-blue-dark
                  pb-5
                ">
                <button
                  v-for="(productItem, index) in productOptions"
                  :key="index"
                  class="
                    font-bold
                    text-sm
                    text-center
                    sm:w-1/4
                    mt-3
                    w-full
                    h-10
                    rounded
                  "
                  :class="{
                    'mr-2': index < productOptions.length - 1,
                    'bg-jooycar-gray-deductibleBtn text-jooycar-blue-darkest border-jooycar-blue-sky border': productItem.value !== product,
                    'bg-jooycar-blue-dark text-white border-jooycar-blue-dark border-2': productItem.value === product,
                  }"
                  @click="product = productItem.value"
                >
                  {{ productItem.label }}
                </button>
              </div>
            </div>
            <div>
              <label
                  class="mr-2 text-sm font-semibold text-jooycar-blue-darkest self-center"
              >
                Límite de responsabilidad civil
              </label>
              <info-tooltip
                :text="tooltipTexts.civilLimit"
              />
              <div
                class="
                  flex
                  sm:flex-row
                  flex-col
                  text-renta-blue-dark
                  pb-5
                ">
                <button
                  v-for="(liabilityItem, index) in liabilityOptions"
                  :key="index"
                  class="
                    font-bold
                    text-sm
                    text-center
                    sm:w-1/6
                    mt-3
                    w-full
                    h-10
                    rounded
                  "
                  :class="{
                    'mr-2': index < liabilityOptions.length - 1,
                    'bg-jooycar-gray-deductibleBtn text-jooycar-blue-darkest border-jooycar-blue-sky border': liabilityItem.value !== distanceFactor,
                    'bg-jooycar-blue-dark text-white border-jooycar-blue-dark border-2': liabilityItem.value === distanceFactor,
                  }"
                  @click="distanceFactor = liabilityItem.value"
                >
                  {{ liabilityItem.label }}
                </button>
              </div>
            </div>
            <div
              v-if="false"
              class="mb-5"
            >
              <div class="flex mb-5">
                <label
                  class="mr-2 text-sm font-semibold text-jooycar-blue-darkest self-center"
                >
                  Asiento de Pasajeros
                </label>
                <info-tooltip
                  :text="tooltipTexts.apov"
                />
              </div>
              <div class="flex">
                <div
                  class="text-xs font-bold cursor-pointer self-end"
                  :class="optionals['Asiento de Pasajeros*'].value == optionals['Asiento de Pasajeros*'].alternatives[0] ?
                  ('text-jooycar-blue-dark') : ('text-jooycar-gray-medium')"
                  @click="() => optionals['Asiento de Pasajeros*'].value = optionals['Asiento de Pasajeros*'].alternatives[0]"
                >
                  {{optionals['Asiento de Pasajeros*'].alternatives[0]}}
                </div>
                <toggle-button
                  @change="ToggleButtonApov"
                  :value="optionals['Asiento de Pasajeros*'].value == optionals['Asiento de Pasajeros*'].alternatives[1]"
                  :sync="true"
                  color="#4066B3"
                  :height="32"
                  :width="52"
                  class="mx-2"
                />
                <div
                  class="text-xs font-bold cursor-pointer self-end"
                  :class="optionals['Asiento de Pasajeros*'].value == optionals['Asiento de Pasajeros*'].alternatives[1] ?
                  ('text-jooycar-blue-dark') : ('text-jooycar-gray-medium')"
                  @click="() => optionals['Asiento de Pasajeros*'].value = optionals['Asiento de Pasajeros*'].alternatives[1]"
                >
                  {{optionals['Asiento de Pasajeros*'].alternatives[1]}}
                </div>
              </div>
            </div>
            <slider-selector
              v-if="false"
              class="mb-12"
              title="Daños y RC por Carga*"
              name="charge-damage"
              :tooltip-text="tooltipTexts.chargeDamage"
              :options="optionals['Daños y RC por Carga*'].alternatives"
              v-model="optionals['Daños y RC por Carga*'].value"
            />
            <slider-selector
              v-if="false"
              class="mb-12"
              title="Defensa Penal y Constitución de Fianzas*"
              name="penal-defense"
              :tooltip-text="tooltipTexts.penalDefense"
              :options="optionals['Defensa Penal y Constitución de Fianzas*'].alternatives"
              v-model="optionals['Defensa Penal y Constitución de Fianzas*'].value"
            />
            <div class="mb-5">
              <div class="flex mb-5">
                <label
                  class="mr-2 text-sm font-semibold text-jooycar-blue-darkest self-center"
                >
                  Daños de vehículo durante viaje en el extranjero
                </label>
                <info-tooltip
                  :text="tooltipTexts.foreignTripsDamages"
                />
              </div>
              <div class="flex">
                <div
                  class="text-xs font-bold cursor-pointer self-end"
                  :class="optionals['Daños de vehículos durante viajes en el extranjero'].value == optionals['Daños de vehículos durante viajes en el extranjero'].alternatives[0] ?
                  ('text-jooycar-blue-dark') : ('text-jooycar-gray-medium')"
                  @click="() => optionals['Daños de vehículos durante viajes en el extranjero'].value = optionals['Daños de vehículos durante viajes en el extranjero'].alternatives[0]"
                >
                  {{optionals['Daños de vehículos durante viajes en el extranjero'].alternatives[0]}}
                </div>
                <toggle-button
                  @change="ToggleButtonForeignTripsDamages"
                  :value="optionals['Daños de vehículos durante viajes en el extranjero'].value == optionals['Daños de vehículos durante viajes en el extranjero'].alternatives[1]"
                  :sync="true"
                  color="#4066B3"
                  :height="32"
                  :width="52"
                  class="mx-2"
                />
                <div
                  class="text-xs font-bold cursor-pointer self-end"
                  :class="optionals['Daños de vehículos durante viajes en el extranjero'].value == optionals['Daños de vehículos durante viajes en el extranjero'].alternatives[1] ?
                  ('text-jooycar-blue-dark') : ('text-jooycar-gray-medium')"
                  @click="() => optionals['Daños de vehículos durante viajes en el extranjero'].value = optionals['Daños de vehículos durante viajes en el extranjero'].alternatives[1]"
                >
                  {{optionals['Daños de vehículos durante viajes en el extranjero'].alternatives[1]}}
                </div>
              </div>
            </div>
            <div class="border-t border-gray-400 py-2"></div>
            <div class="mb-6 bg-jooycar-gray-stepperBg">
              <button
                class="flex items-center justify-between w-full py-3 pl-8 pr-4 font-bold text-jooycar-blue-dark"
                @click="expandedCoverage = !expandedCoverage"
              >
                VER COBERTURAS DEL SEGURO
                <inline-svg
                  :src="require('../assets/images/icons/expand.svg')"
                  class="transition-transform duration-500 transform"
                  :class="{ 'rotate-180': expandedCoverage }"
                />
              </button>
              <div
                class="px-20 pt-10 pb-32 info-collapse"
                v-if="expandedCoverage"
              >
                <div>
                <div class="mb-4">
                    <div class="mb-4 text-3xl font-bold text-jooycar-blue">
                    COBERTURAS BASE
                    </div>
                    <div class="scrolleable-table">
                    <table class="coverage-table" aria-hidden="true">
                        <tr class="text-white bg-jooycar-blue">
                        <th scope="col" class="text-center w-1/3">
                            COBERTURA BASE
                        </th>
                        <th scope="col" class="text-center w-1/3">
                            PLAN TALLER MULTIMARCA
                        </th>
                        <th scope="col" class="text-center w-1/3">
                            PLAN TALLER DE MARCA
                        </th>
                        </tr>
                        <tr class="p-2">
                        <td>Daños materiales al vehículo </td>
                        <td rowspan="8" class="text-center">Valor comercial</td>
                        <td rowspan="8" class="text-center">Valor comercial</td>
                        </tr>
                        <tr class="p-2">
                        <td>Pérdida total</td>
                        </tr>
                        <tr class="p-2">
                        <td>Robo y hurto ante todo evento</td>
                        </tr>
                        <tr class="p-2">
                        <td class="pr-2 overflow-ellipsis">
                            Actos maliciosos
                        </td>
                        </tr>
                        <tr class="p-2">
                        <td>Huelga y terrorismo </td>
                        </tr>
                        <tr class="p-2">
                        <td>Granizo</td>
                        </tr>
                        <tr class="p-2">
                        <td>Riesgos de la naturaleza </td>
                        </tr>
                        <tr class="p-2">
                        <td>Sismo</td>
                        </tr>
                        <tr class="p-2">
                        <td>Robo de accesorios </td>
                        <td class="text-center">
                            Con tope mayor valor entre 15% del valor comercial o UF 50
                        </td>
                        <td class="text-center">
                            Con tope mayor valor entre 15% del valor comercial o UF 50
                        </td>
                        </tr>
                        <tr class="p-2">
                        <td>Responsabilidad civil por daño emergente , daño moral y lucro cesante</td>
                        <td class="text-center">
                            A elegir entre UF 500, UF 1.000, UF 1.500
                        </td>
                        <td class="text-center">
                            A elegir entre UF 500, UF 1.000, UF 1.500
                        </td>
                        </tr>
                        <tr>
                        <td colspan="3" style="border: 0">
                            <div class="my-4 text-3xl font-bold text-jooycar-blue">
                            COBERTURAS ADICIONALES
                            </div>
                        </td>
                        </tr>
                        <tr class="text-white bg-jooycar-blue">
                        <th scope="col" class="text-center w-1/3">
                            COBERTURAS OPCIONALES
                        </th>
                        <th scope="col" class="text-center w-1/3">
                            PLAN TALLER MULTIMARCA
                        </th>
                        <th scope="col" class="text-center w-1/3">
                            PLAN TALLER DE MARCA
                        </th>
                        </tr>
                        <tr class="p-2">
                        <td>
                            Daños materiales durante viaje al extranjero (excluye robo y responsabilidad civil)
                        </td>
                        <td class="text-center">
                            Valor comercial
                        </td>
                        <td class="text-center">
                            Valor comercial
                        </td>
                        </tr>
                        <tr class="p-2">
                        <td>Asiento de pasajero (Plan A,B Y C) </td>
                        <td class="text-center">
                            Incorpora coberturas de muerte accidental UF 200 (Plan A): de incapacidad permanente por UF 200
                            (Plan B) y de gastos médicos con tope UF 25 (Plan C).
                        </td>
                        <td class="text-center">
                            Incorpora coberturas de muerte accidental UF 200 (Plan A): de incapacidad permanente por UF 200
                            (Plan B) y de gastos médicos con tope UF 25 (Plan C).
                        </td>
                        </tr>
                        <tr class="p-2">
                        <td>
                            Vehículo de reemplazo
                        </td>
                        <td class="text-center">
                            A elegir entre 0,15,30 días o ilimitado
                        </td>
                        <td class="text-center">
                            A elegir entre 0,15,30 días o ilimitado
                        </td>
                        </tr>
                        <tr class="p-2">
                        <td>Asistencia en ruta </td>
                        <td class="text-center">
                            A elegir entre básica y full
                        </td>
                        <td class="text-center">
                            A elegir entre básica y full
                        </td>
                        </tr>
                        <tr class="p-2">
                        <td>Robo contenido</td>
                        <td class="text-center">
                            Según condicionado particular
                        </td>
                        <td class="text-center">
                            Según condicionado particular
                        </td>
                        </tr>
                    </table>
                    </div>
                </div>
                <ul style="text-align: justify" class="text-jooycar-blue-darkest">
                    <li class="mb-2">
                    <span class="font-bold">a) Daños materiales:</span> Cubre todos los daños materiales directos que pueda sufrir el vehículo asegurado,
                    sus piezas o partes y sus accesorios, en los términos establecidos en las condiciones generales y particulares.
                    </li>
                    <li class="mb-2">
                    <span class="font-bold">b) Cláusula especial de pérdida total:</span> Se considerará perdida total cuando el costo de reparación de
                    los daños o de reposición de sus piezas o partes supere el 65% del valor del vehículo o cuando el
                    vehículo sea robado o hurtado y no sea recuperado o ubicado en los términos señalados en las condiciones generales.
                    </li>
                    <li class="mb-2">
                    <span class="font-bold">c) Robo de accesorios:</span> Se excluye de la cobertura todo accesorio, pieza o partes no fijadas
                    permanentemente al vehículo tales como tapas de ruedas no apernadas, radios o paneles desmontables.
                    El robo de radios con panel desmontable, solo será indemnizado contra entrega, por parte del asegurado,
                    del panel desmontable a la compañía.
                    </li>
                    <li class="mb-2">
                    <span class="font-bold">d) Responsabilidad civil:</span> Cubre la responsabilidad civil derivada de un accidente en que haya tenido
                    participación el vehículo asegurado en los terminaos establecidos en las condiciones generales y de
                    acuerdo a lo elegido por el asegurado en la contratación de la póliza
                    </li>
                    <li class="mb-2">
                    <span class="font-bold">e) Robo de contenido:</span> La compañía indemnizará la perdida que signifique para el asegurado el robo con
                    fuerza en las cosas y/o violencia en las personas de los objetos asegurados contenidos al interior del
                    vehículo de uso particular detallado en el condicionado general y particular.
                    </li>
                    <li class="mb-2">
                    <span class="font-bold">f) Acto malicioso:</span> se extiende a cubrir los daños que maliciosamente causen terceros al vehículo
                    asegurado, entiéndanse por tales aquellos que se originen en forma consciente, deliberada y con el
                    ánimo o intención de causar dicho deterioro.
                    </li>
                    <li class="mb-2">
                    <span class="font-bold">g) Terrorismo:</span> se extiende a cubrir los daños que directa o indirectamente tengan su origen o fueren
                    consecuencia de huelga, cierre patronal (lock-out), desórdenes públicos o delitos contra el orden
                    público o de terrorismo; así como las perdidas o daños que ocurran a causa de la situación anormal
                    provocada por cualquiera de los hechos mencionados.
                    </li>
                </ul>
                </div>
              </div>
            </div>
            <div class="mb-6 bg-jooycar-gray-stepperBg">
              <button
                class="flex items-center justify-between w-full py-3 pl-8 pr-4 font-bold text-jooycar-blue-dark"
                @click="expandedUncoverage = !expandedUncoverage"
              >
                QUE NO CUBRE EL SEGURO
                <inline-svg
                  :src="require('../assets/images/icons/expand.svg')"
                  class="transition-transform duration-500 transform"
                  :class="{ 'rotate-180': expandedUncoverage }"
                />
              </button>
              <div
                class="px-20 pt-10 pb-32 info-collapse"
                v-if="expandedUncoverage"
              >
                <div class="mb-4 text-3xl font-bold text-jooycar-blue">
                ¿Qué NO cubre?
                </div>
                <div>
                <ol style="text-align: justify" class="text-jooycar-blue-darkest uncoverage-list">
                    <li class="py-2 font-bold">
                    Las exclusiones del producto se encuentran descritas en su condicionado general y particular,
                    dentro de las cuales destacan:
                    </li>
                    <li>
                    Accidentes en los que el conductor esté bajo los efectos del alcohol y/o droga o se haya negado a
                    realizarse el examen de alcoholemia. Huya o abandone el lugar del accidente.
                    </li>
                    <li>
                    Accidentes en lo que el conductor sea responsable de algún delito penado por la ley.
                    </li>
                    <li>
                    Uso del auto distinto al declarado y/o que generen lucro.
                    </li>
                    <li>
                    Mal uso del auto con respecto a lo establecido por el fabricante.
                    </li>
                    <li>
                    Desgaste natural de las piezas del auto.
                    </li>
                    <li>
                    Participación en carreras de auto, concursos, apuestas, entre otros.
                    </li>
                    <li>
                    Accidentes producidos por la circulación en caminos no autorizados para el tránsito regular.
                    </li>
                    <li>
                    Entro otros descritos en los condicionados de la póliza.
                    </li>

                </ol>
                </div>
              </div>
            </div>
            <div class="flex flex-col-reverse items-center mt-10 md:flex-row md:justify-center next-button-box-responsive">
              <button
                v-if="true"
                @click="previousStep"
                class="block w-64 px-4 py-3 mt-2 font-semibold text-center text-white md:mt-auto md:mr-1 bg-jooycar-gray-dark"
              >
                VOLVER
              </button>
              <button
                id="submitBtn"
                @click="nextStep"
                class="block w-64 px-4 py-3 mt-auto font-semibold text-center text-white md:ml-1 bg-jooycar-blue hover:bg-jooycar-blue-dark"
              >
                QUIERO CONTRATAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <restart-form-prompt
      class="mt-4"
      v-if="leadError || !quotes || quotes.length === 0"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import _get from 'lodash.get';
import _find from 'lodash.find';
import { ToggleButton } from 'vue-js-toggle-button';
import { VClosePopover } from 'v-tooltip';
import DataInput from '../components/data-input';
import formatCurrency from '../filters/formatCurrency';
import PriceSummary from '../components/price-summary';
import SliderSelector from '../components/slider-selector';
import AssistancesModal from '../components/assistances-modal';
import Stepper from '../components/stepper';
import StepProgressBar from '../components/step-progress-bar.vue';
import RestartFormPrompt from '../components/restart-form-prompt';
import InfoTooltip from '../components/info-tooltip';
import tooltipTexts from '../helpers/tootltip-texts';
import { BRAND_CODE, MULTIBRAND_CODE } from '../utils/constants';
import hotjar from '../third-party/hotjar';

const MAX_KMS = 1500;

export default {
  components: {
    DataInput,
    PriceSummary,
    SliderSelector,
    Stepper,
    RestartFormPrompt,
    StepProgressBar,
    InfoTooltip,
    ToggleButton,
  },
  data() {
    return {
      optionals: {},
      liabilities: [],
      product: BRAND_CODE,
      deductible: '0UF',
      distanceFactor: 1,
      kilometers: '100 km',
      tooltipTexts,
      expandedCoverage: false,
      expandedUncoverage: false,
      productOptions: [
        { value: BRAND_CODE, label: 'MARCA' },
        { value: MULTIBRAND_CODE, label: 'MULTIMARCA' },
      ],
      totalPrice: 0,
      kmsTraveled: 300,
      calculatedValues: {
        variable: 0,
        additional: 0,
        fixed: 0,
      },
      viewStage: 'Quote',
    };
  },
  mounted() {
    hotjar();

    this.itsMyStage();
    this.extractInfoFromQuote();
  },
  methods: {
    cleanKilometers(kilometers) {
      return Number.parseInt(kilometers, 10) || 0;
    },
    kilometersString() {
      let kilometers = Number.parseInt(this.kilometers, 10) || 0;
      if (kilometers < 0) kilometers = 0;

      this.kilometers = `${kilometers} km`;
    },
    updateKilometers(kilometers) {
      const kilometersNumber = this.cleanKilometers(kilometers);

      if (kilometersNumber <= MAX_KMS) {
        this.kilometers = kilometers;
      }
    },
    showAssistancesModal() {
      this.$modal.show(AssistancesModal, {}, {
        height: 'auto',
        width: '100%',
        maxWidth: 1024,
        scrollable: true,
        adaptive: true,
      });
    },
    nextStep() {
      this.$analytics.logEvent(
        `${this.$analyticsEvents.custom.nextStage.eventName}_3`,
        {
          name: this.$analyticsEvents.stages.c1
        }
      );
      this.$store.dispatch('updateLeadAndRedirect', { leadToUpdate: this.leadToUpdate, routeName: 'C1' });
    },
    previousStep() {
      this.$analytics.logEvent(
        `${this.$analyticsEvents.custom.previousStage.eventName}_1`,
        {
          name: this.$analyticsEvents.stages.l1
        }
      );
      this.$router.push({ name: 'L1' });
    },
    extractInfoFromQuote() {
      if (this.quotes.length > 0) {
        const defaultDeductible = this.brandQuotes.findIndex(quote => quote.metadata.default);
        // Assumes that optionals are always the same, with same alternatives, regardless of deductible or repair
        this.optionals = this.brandQuotes[defaultDeductible].metadata.optionals.reduce((optionalsHash, optional) => {
          optionalsHash[optional.name] = {};
          optionalsHash[optional.name].alternatives = optional.alternatives.map((alternative) => alternative.name);
          const selectedAlternative = optional.alternatives.find(alternative => alternative.default);
          optionalsHash[optional.name].value = selectedAlternative.name || optionalsHash[optional.name].alternatives[0];

          return optionalsHash;
        }, {});
        this.deductible = this.planOptions[defaultDeductible].value;
        this.distanceFactor = this.brandQuotes[defaultDeductible].metadata.liabilities.find(
          liability => liability.default,
        ).distanceFactor;
      }
    },
    ToggleButtonAssistance() {
      if (this.optionals['Asistencia'].value == this.optionals['Asistencia'].alternatives[1]) {
        this.optionals['Asistencia'].value = this.optionals['Asistencia'].alternatives[0]
      } else {
        this.optionals['Asistencia'].value = this.optionals['Asistencia'].alternatives[1]
      }
    },
    ToggleButtonTheft() {
      if (this.optionals['Robo Contenido'].value == this.optionals['Robo Contenido'].alternatives[1]) {
        this.optionals['Robo Contenido'].value = this.optionals['Robo Contenido'].alternatives[0]
      } else {
        this.optionals['Robo Contenido'].value = this.optionals['Robo Contenido'].alternatives[1]
      }
    },
    ToggleButtonForeignTripsDamages() {
      if (this.optionals['Daños de vehículos durante viajes en el extranjero'].value == this.optionals['Daños de vehículos durante viajes en el extranjero'].alternatives[1]) {
        this.optionals['Daños de vehículos durante viajes en el extranjero'].value = this.optionals['Daños de vehículos durante viajes en el extranjero'].alternatives[0]
      } else {
        this.optionals['Daños de vehículos durante viajes en el extranjero'].value = this.optionals['Daños de vehículos durante viajes en el extranjero'].alternatives[1]
      }
    },
    ToggleButtonApov() {
      if (this.optionals['Asiento de Pasajeros*'].value == this.optionals['Asiento de Pasajeros*'].alternatives[1]) {
        this.optionals['Asiento de Pasajeros*'].value = this.optionals['Asiento de Pasajeros*'].alternatives[0]
      } else {
        this.optionals['Asiento de Pasajeros*'].value = this.optionals['Asiento de Pasajeros*'].alternatives[1]
      }
    },
    kmPrice() {
      const maxDistance = this.selectedPlan.maxDistance;
      const fixedPrice = this.calculatedValues.fixed;
      const AdditionalsPrice = this.calculatedValues.additional;
      const variablePriceCalculated = this.kmsTraveled <= maxDistance ? (
        this.kmsTraveled * this.calculatedValues.variable
      ) : (
        this.calculatedValues.variable * maxDistance
      );

      return fixedPrice + AdditionalsPrice + variablePriceCalculated;
    },
    itsMyStage () {
      let stageInHistory = false
      const correctStage = this.lead.currentHistory.find(history => history.stage == this.viewStage) > 0;

      this.lead.currentHistory.forEach(history => {
        if (history.stage == this.viewStage) stageInHistory = true;
      });

      if (!correctStage && !stageInHistory) {
        this.$router.push({ name: this.lead.currentStage });
      }
    }
  },
  computed: {
    ...mapState(['leadId', 'lead', 'leadError']),
    ...mapGetters(['quotes', 'brandQuotes']),
    selectedPlan() {
      return this.$store.getters.selectedPlan(this.product, this.deductible);
    },
    selectedOptionals() {
      return this.selectedPlan.optionals;
    },
    planOptions() {
      return this.brandQuotes.map(plan => (
        {
          label: `${plan.metadata.deductible.split('UF')[0]} UF`,
          value: plan.metadata.deductible,
        }
      ));
    },
    liabilityOptions() {
      return _get(this.selectedPlan, 'liabilities', []).map(liability => (
        {
          value: liability.distanceFactor,
          label: `${liability.code} UF`,
        }
      ));
    },
    selectedLiability() {
      return _find(this.selectedPlan.liabilities, { 'distanceFactor': this.distanceFactor });
    },
    selectedRelatedOptionals() {
      return Object.entries(this.optionals).reduce((acc, [name, { value }]) => {
        const relatedOptionals = this.selectedOptionals[name].alternatives[value].relatedOptionals;

        return acc.concat(relatedOptionals);
      }, []);
    },
    selectedRelatedOptionalCodes() {
      return this.selectedRelatedOptionals.map((optional) => optional.metadata.optionalCode, 0);
    },
    leadToUpdate() {
      return {
        ...this.lead,
        currentStage: 'C1',
        stages: {
          ...this.lead.stages,
          quote: {
            plan: this.deductible,
            productCode: this.product,
            liability: this.selectedLiability.code,
            optionals: this.selectedRelatedOptionalCodes,
          },
        },
      };
    },
    fixedPriceOptionals() {
      return Object.entries(this.optionals).reduce((acc, [name, { value }]) => {
        const relatedOptionals = this.selectedOptionals[name].alternatives[value].relatedOptionals.filter(
          optional => optional.fixedPrice,
        );

        if (relatedOptionals.length > 0 || name == 'Asistencia') {
          return acc.concat([{
            name,
            value,
            relatedOptionals,
          }]);
        }

        return acc;
      }, []);
    },
  },
  filters: {
    formatCurrency(value) {
      return formatCurrency(value);
    },
    formatNumber(value) {
      return formatCurrency(value, false);
    },
  },
  watch: {
    quotes() {
      this.extractInfoFromQuote();
    },
  },
  directives: {
    'close-popover': VClosePopover,
  },
};
</script>

<style lang="scss" scoped>
  .scrolleable-table {
    overflow-x: auto;
  }
  .coverage-table {
    tr {
      th {
        border-left: 1px solid #4066b3;
        border-right: 1px solid #4066b3;
      }
      td {
        border: 1px solid;
        padding: 5px;
      }
    }
  }
  .uncoverage-list {
    list-style: inherit;

    :nth-child(1) {
      list-style: none;
    }
  }
  .next-button-box-responsive {
    display: none;
  }

  @media (max-width: 514px) {
    .responsive-kms-calculator {
      flex-direction: column;

      div:nth-child(1) {
        margin-bottom: 5px;
        margin-right: 0;
      }
      label:nth-child(2) {
        margin-right: 0;

        div:nth-child(2) {
          input {
            text-align: center;
          }
        }
      }
      div:nth-child(3) {
        margin-top: 10px;
        margin-right: 0;
      }
      div:nth-child(4) {
        margin-right: 0;
        width: inherit;
      }
    }
  }

    // custom input range [chrome, safari, opera, edge]
  input[type=range]{
      -webkit-appearance: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
      width: 300px;
      height: 5px;
      background: #ddd;
      border: none;
      border-radius: 3px;
  }
  input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 60px;
      width: 48px;
      cursor: pointer;
      margin-top: -44px;
      background-image: url("../assets/images/icons/car-slider.svg");
      background-repeat: no-repeat;
  }
  // custom input range [firefox]
  input[type=range]::-moz-range-track {
      height: 5px;
      background: #ddd;
      border: none;
      border-radius: 3px;
  }
  input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    border: none;
    height: 90px;
    width: 48px;
    cursor: pointer;
    background-image: url("../assets/images/icons/car-slider.svg");
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .full-section {
    margin-bottom: -20%;
  }

  @media (max-width: 1400px) {
    .responsive-replace {
      flex-direction: column;
    }
    .responsive-replace-button-box > button {
      width: 20%;
      margin-top: 8px;
    }
  }

  @media (max-width: 1023px) {
    .section-additionals {
      padding-left: 20px;
      padding-right: 20px;
    }
    .responsive-replace {
      flex-direction: column;
    }
    .theft {
      margin-bottom: 20px;
      flex-direction: column;

      >:nth-child(2) {
        margin-top: 20px;
      }
    }
    .assistance {
      margin-bottom: 20px;
      flex-direction: column;

      >:nth-child(2) {
        margin-top: 20px;
      }
    }
    .next-button-box {
      display: none;
    }
    .variable-title {
      padding-top: 20px;
    }
    .responsive-replace-button-box > button {
      width: 12%;
      margin-top: 8px;
    }
    .next-button-box-responsive {
      display: flex;
      margin-bottom: 50px;
    }
    .full-section {
      margin-bottom: 0%;
    }
    .no-bg-white {
      background-color: transparent;
    }
  }
  @media (max-width: 775px) {
    .responsive-replace-button-box > button {
      width: 15%;
      margin-top: 8px;
    }
  }
  @media (max-width: 700px) {
    .info-collapse {
      padding: 20px;
    }
  }

  @media (max-width: 639px) {
    .responsive-replace-button-box {
      flex-direction: column;
    }
    .responsive-replace-button-box > button {
      width: 100%;
      margin-top: 8px;
    }
  }
</style>
